import classNames from "classnames";
import { setData } from "rivals/shared/utils/qa";
import styles from "./Spinner.module.scss";
import type { Props } from "./types";

const Spinner = ({ className = "", philter = "" }: Props): JSX.Element => (
  <div
    className={classNames(styles.spinner, className)}
    role="status"
    {...setData("loading-spinner", philter)}
  />
);

export default Spinner;
